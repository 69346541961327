@import '../../styles/colors';

.settings__modal {
  position: fixed;
  background: white;
  width: 380px;
  height: 100%;
  right: 0;
  top: 0;
  animation: animate 0.5s linear;
  transition: 1ms;
  border-left: 1px solid $blue;
  min-height: var(--app-height);
  z-index: 1000;

  @keyframes animate {
    0% {
      right: -1000px;
    }
    100% {
      right: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 70px;
    background: $blue;

    h3 {
      display: flex;
      align-items: center;
      font-size: 25px;
      font-weight: 200;
      color: white;
    }
  }

  &-icon {
    color: white !important;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 50%;
    padding: 5px;
  }

  &-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    align-items: flex-start;
  }

  &-block {
    margin-bottom: 30px;

    p {
      color: black;
      margin-bottom: 20px;
      font-size: 14px;
      text-align: left;
    }
  }

  &-btn {
    background: $blue;
    border: 1px solid $blue;
    height: 30px;
    color: white;
    cursor: pointer;
    width: 90px;
  }

  &-btn:hover {
    background: $blue-hover;
  }

  h4 {
    margin-bottom: 20px;
  }

  h6 {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
  }

  &-fonts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  &-fonts-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    label {
      display: flex;
      align-items: center;
      color: black;
    }

    input {
      margin-right: 5px;
      color: black;
    }
  }

  &-fonts-list {
    display: flex;
    flex-direction: column;
  }
}
