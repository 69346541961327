@import '../../styles/colors';

.presentation-customize {
  width: 350px;
  top: 0;
  right: 0;
  position: fixed;
  height: 100%;
  background: white;
  padding-bottom: 20px;
  z-index: 100;

  &-header {
    background: $blue;
    display: flex;
    align-items: center;
    padding: 15px 0;
    text-transform: uppercase;
    justify-content: space-around;

    h2 {
      color: white;
      font-size: 18px;
      font-weight: 300;
    }

    button {
      background: transparent;
      border: 2px solid white;
      padding: 6px 12px;
      color: white;
      font-weight: 300;
      cursor: pointer;
    }
  }

  &-icon {
    font-size: 16px;
    color: white !important;
    border: white 1px solid;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }

  &-body {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
  }

  &-search {
    padding: 10px 5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    h2 {
      color: rgb(32, 120, 201);
      text-transform: uppercase;
      font-weight: 300;
      font-size: 18px;
    }

    input[type='text'] {
      width: 80%;
    }

    label {
      display: flex;
      align-items: center;
      margin-top: 10px;
      width: 15%;
      margin-left: auto;
      gap: 5px;
      font-size: 16px;
      color: rgb(32, 120, 201);
    }
  }

  &-list {
    list-style: none;
    padding: 0 10px;
    margin: 20px 0 0 0;
    overflow: auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: calc(var(--app-height) - 200px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185B97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 12px;
      color: rgb(32, 120, 201);
    }

    img {
      width: 140px;
      height: 90px;
    }
  }
}
