@import '../../styles/colors';

.card {
  display: flex;
  flex-direction: column;
  min-width: 210px;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 2px;
  max-width: 220px;

  &-disabled {
    background: #cacdd2 !important;
  }

  &__border {
    border: 2px solid $blue;
  }

  &__border-none {
    border: none;
    background: $blue-hover;
    padding: 2px 4px;
  }

  &__img {
    min-width: 202px;
    min-height: 212px;
    max-width: 212px;
    max-height: 212px;

    img {
      min-width: 202px;
      min-height: 202px;
      padding: 5px 0;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    background: $blue;
    color: white;
    padding: 10px;
    line-height: 1.3;
    font-size: 14px;
    z-index: 2;
    margin: 5px 0;
    height: 70px;
  }

  &__title-border {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -3px;
      right: -3px;
      bottom: -5px;
      background: rgba(29, 106, 176, 0.6);
      z-index: -1;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 49%;
      min-height: 100px;
      background: #cacdd2;
      font-size: 12px;
      color: $blue;
      padding: 10px;

      p {
        font-weight: 500;
      }
    }
  }

  .date-icon {
    margin: auto 0 0 auto;
    font-size: 20px;
    color: #d5d7db;
  }

  &__date-border {
    background: rgba(29, 106, 176, 0.6);
    padding: 0 4px;
    margin: 0 -4px;
  }

  &__date-border-none {
    padding: 0 2px;
    margin: 0 -3px;
  }

  &__stars {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    border: 2px solid #cacdd2;
    z-index: 1000;
  }

  &__stars-border {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: $blue-hover;
      z-index: 1;
    }
  }
}

.ant-rate {
  z-index: 10000;
  color: $blue !important;
}

.ant-rate-star:not(:last-child) {
  margin-right: 15px !important;
}
