p,
h1,
h2,
h3,
h4,
h5,
body {
  margin: 0;
  font-weight: 400;
  line-height: 1;
  font-family: 'Segoe UI', sans-serif;
  height: 100%;
  max-height: 100vh;
}

body {
  overflow: auto;
}

img {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

#root {
  height: 100%;
  max-height: 100vh;
}

code {
  font-family: 'Segoe UI', sans-serif;
}

input::placeholder {
  font-family: 'Segoe UI', sans-serif;
}

* {
  box-sizing: border-box;
  transition: all 0.2s;
}

.lazyload-wrapper {
  height: 100%;
}

:root {
  --app-height: 100vh;
}

html {
  overflow: auto;
}
