@import '../../styles/colors';

.presentation-page {
  width: 100vw;
  height: var(--app-height);
  aspect-ratio: 16 / 9;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: var(--app-height);
}

.spinner {
  margin: auto !important;
}

.slide-show {
  display: flex;
  overflow: hidden;
}

.slide {
  min-width: 100vw;
  height: var(--app-height);
}

.slide-edetailer {
  height: calc(var(--app-height) - 50px);
}

.slide-notes {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding-top: 100px;

  img {
    width: 48%;
    height: 500px;
  }

  .video {
    width: 48%;
    height: 500px;
  }
}

.notes {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--article);
    margin-bottom: 10px;
  }

  p,
  b,
  span {
    font-size: var(--main-text);
    margin-bottom: 10px;
  }
}

.slide-button {
  position: absolute;
  top: 50%;
  font-size: 25px;
  background: rgba(204, 204, 204, 0.7);
  transform: translate(0, -50%);
  padding: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 9999999;
}

.next-slide {
  right: 0;
}

.prev-slide {
  left: 0;
}
