@import '../../styles/colors';

.auth-field__label {
  position: relative;
  margin-bottom: 20px;
  min-width: 300px;
  text-align: center;
  color: $blue;
  display: flex;
  flex-direction: column;
}

.auth-field__label-content {
  margin-bottom: 5px;
  font-weight: 500;
}

.auth-field {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  background: #e8f0fe;
  border: 2px solid #e8f0fe;
  color: black;

  &-error {
    border-color: #fd7a7a;
  }
}

.auth-field:focus {
  background: #e8f0fe;
  border: 2px solid #e8f0fe;
}

.auth-field:hover {
  background: white;
  border: 2px solid;
}

.auth-field::placeholder {
  font-size: 14px;
  padding-left: 5px;
}

.auth-field:focus {
  border: 2px solid $blue;
}

.auth-field--icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
}
