@import '../../styles/colors';

.email-send {
  position: fixed;
  background: white;
  width: 380px;
  right: 0;
  top: 0;
  animation: animate 0.5s linear;
  transition: 1ms;
  border-left: 1px solid $blue;
  min-height: calc(var(--app-height) - 90px);
  z-index: 1000;

  @keyframes animate {
    0% {
      right: -1000px;
    }
    100% {
      right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 70px;
    background: $blue;
    margin-bottom: 15px;
    padding: 0 15px;

    &-inner {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    button {
      border: 1px solid white;
      font-size: 14px;
      text-align: center;
      width: 80px;
      height: 32px;
      color: white;
      background: transparent;
      cursor: pointer;
    }

    h3 {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: 100;
      color: white;
      text-transform: uppercase;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px;
  }

  &__label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    &-btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: 22px;
      width: 40px;
      border: 2px solid transparent;
      background: $blue;
      cursor: pointer;
    }

    input {
      flex: 1;
      background: #f2f2f2;
      border: 2px solid transparent;
    }

    textarea {
      background: #f2f2f2;
      flex: 1;
      border: 2px solid transparent;
    }

    p {
      width: 70px;
    }
  }

  &__body-list {
    overflow: auto;
    height: calc(var(--app-height) - 400px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185B97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }
  }

  &__body-list-item {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 10px;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid $blue;
      background: $blue-hover;
    }

    &--active {
      background: $blue-hover;

      &:hover {
        background: $blue !important;
        color: white !important;
      }
    }

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.email-send-search {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  input {
    background: #f2f2f2;
    border: 2px solid transparent;
    width: 70%;
    height: 32px;
  }
}

.email-send-search-list {
  overflow: auto;
  height: calc(var(--app-height) - 200px);

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #185B97;
  }

  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }
}

.pt-10 {
  padding-top: 10px;
}

.email-send__img {
  min-width: 80px;
  min-height: 80px;
}