@import '../../styles/colors';

.auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: var(--app-height);
  background: #fff;
  padding-bottom: 10%;

  &__body {
    margin: auto;
    display: flex;
    flex-direction: column;

    a {
      color: $blue;
      text-decoration: none;
      font-weight: normal;
      margin-top: 20px;
      text-align: right;
    }

    a:hover {
      font-weight: 500;
    }
  }

  &__footer {
    height: 60px;
    background: $blue;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  &-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    button {
      width: 46%;
    }
  }

  &__button {
    background: $blue;
    color: white;
    font-size: 16px;
    border: 1px solid $blue;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;

    &:hover {
      background: $blue-hover;
    }
  }

  &__button-cancel {
    background: #cccccc;
    color: white !important;
    font-size: 16px;
    border: 1px solid #cccccc;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    text-decoration: none;
    width: 46%;
    text-align: center !important;
    &:hover {
      background: rgba(204, 204, 204, 0.5);
    }
  }

  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: 16px -16px 0 !important;
  }
}
