html[data-fonts='small'] {
  --main-text: 12px;
  --article: 24px;
  --icon-text: 10px;
}

html[data-fonts='medium'] {
  --main-text: 14px;
  --article: 26px;
  --icon-text: 12px;
}

html[data-fonts='large'] {
  --main-text: 16px;
  --article: 28px;
  --icon-text: 13px;
}

html[data-fonts='extra-large'] {
  --main-text: 18px;
  --article: 30px;
  --icon-text: 14px;
}

html[data-fonts='huge'] {
  --main-text: 20px;
  --article: 32px;
  --icon-text: 15px;
}
