@import '../../styles/colors';

.presentation-audience {
  z-index: 100000;
  height: calc(var(--app-height) - 90px);
  min-width: 300px;
  position: absolute;
  background: white;
  top: 85px;
  right: 360px;
  border: 1px solid black;
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  &-list {
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: 80%;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185B97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }
  }

  &-list-item {
    cursor: pointer;
    padding: 10px;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid $blue-hover;
    }
  }

  &-list-item__active {
    border: 2px solid $blue;
  }
}
