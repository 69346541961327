@import '../../styles/colors';

.footer {
  text-align: center;
  color: #67757c;
  font-size: 14px;
  margin-top: auto;
  background: white;
  line-height: 1.5;
  font-weight: 300;
  background: $blue;
  padding: 0 25px;
  vertical-align: center;

  &__inner {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 850px) {
      height: auto;
      padding: 10px 0;
    }
  }

  &__nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 850px) {
      flex-wrap: wrap;
    }
  }

  &__nav-second {
    width: 250px;

    @media screen and (max-width: 1200px) {
      width: 200px;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 80px;

    @media screen and (max-width: 850px) {
      width: 30%;
    }
  }

  .icon {
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 50%;
    padding: 5px;
    border: 3px solid white;
  }

  .icon-spin {
    animation: spin 1.5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  p {
    font-size: 10px;
    color: white;
  }
}
