@import './colors';
@import './fonts-size';

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--app-height));
  background: #eef5f9;
  max-height: 100vh;

  @media screen and (max-width: 1280px) {
    max-height: initial;
  }

  @media screen and (max-width: 720px) {
    min-width: 720px;
  }
}

.spinner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(var(--app-height) - 90px);
  @media screen and (max-width: 1300px) {
    height: 100%;
  }
}

.spinner {
  margin: auto !important;
}

.main-block {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--app-height) - 90px);

  @media screen and (max-width: 1300px) {
    padding: 0;
    min-height: initial;
  }

  @media screen and (max-width: 720px) {
    min-width: 720px;
  }

  h1 {
    color: $blue;
    font-size: 45px;
    padding: 20px 0 0 50px;
    font-weight: 300;

    @media screen and (max-width: 1400px) {
      font-size: 36px;
      padding-top: 15px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 30px;
    }
  }

  &__content {
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: auto 0;
    gap: 10px;

    @media screen and (min-width: 1600px) {
      margin: auto 0;
    }

    @media screen and (max-width: 800px) {
      margin: 20px 0;
    }
  }

  &__spinner-container {
    position: absolute;
    top: -90px;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(232, 232, 232, 0.49);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 100000;
  }
}

.back-icon {
  cursor: pointer;
  margin-right: 10px;
  color: $blue !important;

  &:hover {
    background: $blue-hover;
    border-radius: 50%;
  }
}

.pwa-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 16px;
  -webkit-box-shadow: 0 0 8px 1px #cccccc;
  box-shadow: 0 0 8px 1px #ccc;
  padding: 0 30px;
  flex-wrap: wrap;

  h3 {
    width: 100%;
    height: 20px;
    margin-top: 10px;
    line-height: 0;
    text-align: center;
    color: $blue;
  }
}

.pwa-cancel {
  padding: 5px 10px;
  background: transparent;
  outline: none;
  border: 1px solid black;
  border-radius: 8px;
  cursor: pointer;
  font-style: italic;
}

.pwa-install {
  padding: 5px 10px;
  background: transparent;
  outline: none;
  border: 1px solid black;
  border-radius: 8px;
  cursor: pointer;
  font-style: italic;
}

.ant-spin-lg .ant-spin-dot {
  @media screen and (max-width: 1280px) {
    font-size: 46px !important;
  }
}

.ant-spin-lg .ant-spin-dot i {
  @media screen and (max-width: 1280px) {
    width: 20px !important;
    height: 20px !important;
  }
}

.ant-spin-dot-item {
  background-color: $blue !important;
}

.ant-modal {
  top: 50% !important;
  transform: translate(0, -100%) !important;
}
