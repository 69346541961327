.references {
  display: flex;
  flex-direction: column;
  min-height: 400px;

}

.references__modalImg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.3);
}

.references__modalImg_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.references__modal {
  @media screen and (max-width: 1920px) {
    width: 60% !important;
  }

  @media screen and (max-width: 1600px) {
    width: 65% !important;
  }

  @media screen and (max-width: 1420px) {
    width: 70% !important;
  }
  @media screen and (max-width: 1300px) {
    width: 80% !important;
  }

  @media screen and (max-width: 1000px) {
    width: 90% !important;
  }

  background: #eef5f9;
  border-radius: 2px;

  .ant-modal-body {
    background: #eef5f9;
    min-height: 400px;
  }

  .ant-modal-header {
    background: #185b97;
    text-align: center;
  }

  .ant-modal-title {
    color: white !important;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: normal !important;
  }
}

.ant-modal {
  transform: translate(0, -50%) !important;
}

.ant-modal-close-x {
  color: white;
  font-size: 22px !important;
}

.ant-modal-content {
  background: transparent;
}
.not-reference {
  margin: auto;
}
