@import '../../styles/colors';

.pdf {
  margin: 0 auto;
  height: var(--app-height);
  display: flex;
  justify-content: center;
  overflow: auto;
  width: 100vw;


  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #185b97;
  }

  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }

  &__page {
    width: 100vw;
    min-height: var(--app-height);

    @media screen and (max-width: 1200px) {
      min-height: calc(var(--app-height) - 90px);
    }
  }

  &__page:hover {
    border-top: 2px solid $blue;
    border-bottom: 2px solid $blue;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
