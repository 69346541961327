@import '../../styles/colors';

.list {
  z-index: 100;
  height: calc(var(--app-height) - 90px);
  width: 25%;
  min-width: 350px;
  position: absolute;
  background: white;
  top: 85px;
  right: 15px;
  border: 1px solid black;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #185B97;
  }

  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 70%;
    top: -49px;
    border: 25px solid transparent;
    border-bottom: 25px solid white;
    transform: translate(-70%, 0);
    z-index: 10;
    box-shadow: 0 0 0 0 black;
  }

  img {
    width: 50px;
    height: 50px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 5px;
    gap: 5px;
    cursor: pointer;
    border: 2px solid transparent;

    &-active {
      border: 2px solid $blue;
      transition: all 0.2s ease-in;
    }
  }
}

.e-list {
  top: 65px;
  left: 50px;
  height: calc(var(--app-height) - 120px);
  border: 2px solid $blue;
}
