@import '../../styles/colors';

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(var(--app-height) - 90px);;

  &__content {
    flex: 1;
    padding: 30px;
    min-height: calc(var(--app-height) - 90px);;

    h2 {
      margin-top: 20px;
      color: $blue;
      margin-bottom: 30px;
      font-size: 40px;
      font-weight: 300;
    }
  }

  &-icon {
    border-radius: 50%;
    border: 2px solid white;
    padding: 5px;
    font-size: 18px;
  }

  &__list {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: calc(85vh - 90px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185B97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }

    &-flag {
      width: 110px;
      @media screen and (max-width: 1366px) {
        width: 25px;
      }
    }
  }

  &__list-icon {
    padding: 5px;
    color: white;
    border-radius: 50%;
    font-size: 18px;
    border: 2px solid white;
  }

  &__list-item {
    background: $blue;
    width: 100%;
    color: white;
    display: grid;
    grid-template-columns: 32px 100px 1fr;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    margin-bottom: 10px;
    gap: 40px;

    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }

  &__list-item:hover .search__list-icon {
    border-color: #1890ff !important;
  }

  &__panel {
    padding: 30px;
    width: 35%;
    background: $blue;
    min-height: calc(var(--app-height) - 90px);
    max-width: 350px;

    h2 {
      color: white;
      margin-bottom: 20px;
      font-size: 40px;
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    input {
      width: 80%;
      height: 30px;
      border: transparent;
      padding-left: 10px;
    }

    &-label {
      display: flex;
      align-items: center;

      button {
        width: 40px;
        height: 30px;
        border: transparent;
        cursor: pointer;
      }
    }
  }
}
