.forgot-password {
  padding-bottom: 5%;

  button {
    margin: 0;
  }

  a {
    margin: 0;
  }
}