@import '../../styles/colors';

.nav-top {
  width: 100%;
  position: fixed;
  top: 0;
  left: 30%;
  height: 90px;
  opacity: 0;
  z-index: 1;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.nav-top-active {
  width: 100%;
  opacity: 1;
  transition: all 0.2s ease-in;
  position: fixed;
  padding-right: 20px;
  top: 0;
  left: 0;
  height: 90px;
  background: rgb(32, 120, 201);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 1295px) {
    position: initial;
  }
}

.nav-top-active__inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 20px;
  width: 100%;

  @media screen and (max-width: 600px) {
    width: 100%;
    overflow: auto;
    justify-content: initial;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185b97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }
  }
}

.nav-top__item {
  color: white;
  background: rgba(0, 71, 138, 0.5);
  padding: 9px 25px 9px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: max-content;

  p {
    font-size: 12px;
    font-weight: 300;
  }
}

.nav__item-icon {
  font-size: 20px;
}

.nav-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 80px;
  opacity: 0;
  z-index: 1;
  transition: all 0.1s ease-in;

  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
  }

  &-block-notes {
    flex-direction: column-reverse;
    color: $blue;
  }
}

.nav-bottom-active {
  width: 100%;
  opacity: 1;
  transition: all 0.1s ease-in;
  position: fixed;
  bottom: 0;
  height: 190px;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #185b97;
  }

  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }

  img {
    min-width: 160px;
    min-height: 130px;
    max-width: 160px;
    max-height: 130px;
    margin: 5px 10px;
    cursor: pointer;
  }

  img:hover {
    border: 2px solid $blue;
  }
}

.e-detailer-nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    padding: 10px;
  }

  &__item {
    color: white;
    cursor: pointer;

    & > span {
      font-size: 30px;
    }
  }

  img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    object-fit: contain;
  }
}

.e-detailer-top-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;

  &__inner {
    display: flex;
    align-items: center;
    padding: 0;
    height: 50px;
    overflow-x: auto;
    width: calc(100vw - 1px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #185b97;
    }

    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }
  }

  &__item {
    min-width: 100px;
    cursor: pointer;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: none;
    border: 1px solid transparent;
    border-right: 2px solid white;

    &:last-child {
      border-right: transparent;
    }
  }
}
